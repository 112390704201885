import { useQuery } from "react-query"
import { axiosClient } from "lib/axiosClient"

const getHubspotSettings = async (context) => {
  const { signal } = context
  const { data: settings } = await axiosClient({
    url: '/settings/hubspot',
    signal
  })
  return settings
}

export const useGetHubspotSettings = ({ onSuccess }) => {
  return useQuery(['settings-hubspot'], {
    queryFn: getHubspotSettings,
    onSuccess
  })
}