import { useEffect, useState } from 'react'
import {
  Flex,
  HStack,
  Text,
  Spacer,
  Heading
} from '@chakra-ui/react'
import { SalesTable } from './Table'
import { DateRange } from '../../DateRangePicker'
import { ControlledUserSelect } from 'components/Form/ControlledUserSelect'
import { getFirstOfMonthDate } from 'utils'
import { useGetSalesReport } from 'features/Reports/api/getSalesReport'
import { Loading } from 'components/Loading'
import { useFilter } from './useFilter'
import { useGetQuotesSalesReport } from 'features/Reports/api/getQuoteSalesReport'
import SomethingBroke from 'components/Errors/SomethingBroke'

export const QuoteSales = () => {
  const [engineer, setEngineer] = useState()
  const [startDate, setStartDate] = useState(
    getFirstOfMonthDate()
  )
  const [endDate, setEndDate] = useState(new Date())
  const { filterString } = useFilter({ engineer, startDate, endDate })
  const report = useGetQuotesSalesReport(filterString)

  if(report.error) {
    return <SomethingBroke />
  }

  if(report.isLoading || report.isFetching) {
    return <Loading text="Preparing Report" />
  }

  return (
    <Flex mt='1rem' justifyContent='flex-start' width='80%' flexFlow='column'>
      <HStack mt='1rem' mb='1rem'>
        <Heading color='resolveOrange'>Quote Sales Report</Heading>
        <Spacer />
        <HStack w='40%'>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <ControlledUserSelect
            onChange={engineer => setEngineer(engineer)}
            value={engineer}
            isClearable={true}
            mr='1rem'
          />
        </HStack>
      </HStack>
      { report.data ? <SalesTable tableData={report.data} /> : <Text>No items found</Text> }
    </Flex>
  )
}
