import axios from "axios"
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getSalesReport = async (context) => {
  const { signal, queryKey } = context
  const [_, filterString] = queryKey

  const cancelToken = axios.CancelToken
  const source = cancelToken.source

  const { data } = await axiosClient({
    url: `/reports/quotes?${filterString}`,
    signal,
    cancelToken: source.token
  })

  signal?.addEventListener('abort', () => {
    source.cancel("Quotes report query was cancelled")
  })

  return data
}

export const useGetQuotesSalesReport = (filterString) => {
  return useQuery(['quote-sales-report', filterString], {
    queryFn: getSalesReport,
    placeholderData: []
  })
}