import { Button, Flex, FormControl, FormLabel, Input, Spacer } from '@chakra-ui/react'

export const Form = ({ handleSubmit, handleUpdate, register, testHubspotAPI }) => {
  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <FormControl mt='2rem'>
        <FormLabel color='resolveOrange'>Access Token</FormLabel>
        <Input type='password' {...register('hubspot_access_token')} />
      </FormControl>

      <Flex mt='2rem'>
        <Button type='submit' backgroundColor='resolveOrange' color='white'>
          Save
        </Button>
        <Spacer />
        <Button
          backgroundColor='resolveOrange'
          isDisabled
          onClick={() => testHubspotAPI()}
          color='white'
        >
          Test
        </Button>
      </Flex>
    </form>
  )
}
