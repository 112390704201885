import { Button, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ItemClientCostInput } from 'components/Form/ItemClientCostInput';
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput';
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput';
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput';
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput';
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput';
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput';
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput';
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput';
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput';
import { SetProductSelectInput } from 'components/Form/SetProductSelectInput';
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput';
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput';
import { HubspotDealSelect } from 'components/Form/HubspotDealSelect';

export const Form = ({
    addItem,
}) => {

    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useFormContext();

    const productTypeId = useWatch({ control, name: 'type_id' });
    const watchSupplier = useWatch({ control, name: 'supplier_id' });
    const watchClientId = useWatch({ control, name: 'client_id' });

    return (
        <form onSubmit={handleSubmit(addItem)}>
            <SupplierSelectInput
                name="supplier_id"
                register={register}
                ignoreOtherSupplier={false}
                required="Please select a supplier"
                control={control}
                errors={errors}
            />

            <SupplierProductTypeSelectInput
                name="type_id"
                errors={errors}
                control={control}
            />

            {productTypeId && watchSupplier && (
                <SetProductSelectInput
                    supplierId={watchSupplier}
                    required={false}
                    name="set_product"
                    type={productTypeId}
                    control={control}
                />
            )}

            {productTypeId?.value === 2 && (
                <FormControl mt="1rem" mb="1rem" isRequired>
                    <FormLabel>Subscription Options</FormLabel>
                    <Flex>
                        <MinimumTermSelectInput
                            required="Please select a minimum term"
                            control={control}
                            errors={errors}
                            name="minimum_term_id"
                            mr="1rem"
                        />
                        <PaymentPlanSelectInput
                            required="Please select a payment plan"
                            control={control}
                            errors={errors}
                            name="payment_plan_id"
                        />
                    </Flex>
                </FormControl>
            )}

            <ItemDescriptionInput
                register={register}
                required
                mb="1rem"
                errors={errors}
                name="description"
                rules={{
                    maxLength: {
                        value: 90,
                        message: 'Description must be under 90 characters',
                    },
                }}
            />

            <Flex mb="1rem">
                <ItemPartCodeInput
                    register={register}
                    errors={errors}
                    mr="1rem"
                    flexBasis="50%"
                    name="part_code"
                    showLabel
                />
                <HubspotDealSelect clientId={watchClientId?.value} required />
            </Flex>

            <ItemInternalDescriptionInput
                register={register}
                name="internal_description"
                errors={errors}
            />

            <Flex mb="1rem">
                <ItemQuantityInput
                    mr="1rem"
                    control={control}
                    required
                    name="quantity"
                    errors={errors}
                />
                <ItemUnitPriceInput
                    control={control}
                    required
                    name="unit_price"
                    errors={errors}
                    setValue={setValue}
                />
                <ItemMarkupInput
                    control={control}
                    required
                    name="mark_up_percentage"
                    errors={errors}
                    setValue={setValue}
                />
            </Flex>

            <Flex mb="2rem">
                <ItemClientCostInput
                    control={control}
                    required
                    name="client_cost"
                    errors={errors}
                    setValue={setValue}
                />
                <ItemDeliveryPriceInput
                    control={control}
                    required
                    name="delivery_price"
                    errors={errors}
                />
            </Flex>

            <Button color="white" backgroundColor="resolveOrange" type="submit">
                Add Item
            </Button>
        </form>
    );
};
