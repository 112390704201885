import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getProducts = async (context) => {
  const { signal } = context
  const { data: products } = await axiosClient({
    url: '/settings/products',
    signal
  })
  return products
}

export const useGetProducts = () => {
  return useQuery(['settings-products'], {
    queryFn: getProducts,
    placeholderData: []
  })
}