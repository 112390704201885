import { useForm } from 'react-hook-form'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'
import { useGetQuoteType } from 'features/Settings/api/getQuoteType'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'

const FormHeader = ({ name }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        {name}
      </Text>
    </Flex>
  )
}

const QuoteTypeState = {
  label: '', 
  options: ''
}

export const EditQuoteType = ({ quoteTypeId, ...restProps }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: QuoteTypeState })

  const handleUserUpdate = form => {
    // TODO: Implement edit quote type
  }

  const onSuccess = quoteType => {
    reset(quoteType)
  }
  const quoteType = useGetQuoteType({ quoteTypeId, onSuccess })

  if(quoteType.isLoading) return <Loading />
  if(quoteType.error) return <SomethingBroke message='Failed to get quote type' error={quoteType.error.message} />
  return (
    <Flex mt='1rem'>
      <form onSubmit={handleSubmit(handleUserUpdate)} {...restProps}>
        <FormHeader name='Edit Quote Type' />
        <FormControl>
          <FormLabel>Label</FormLabel>
          <Input {...register('label')} />
        </FormControl>
        <FormControl>
          <FormLabel>Options</FormLabel>
          <Input {...register('options')} />
        </FormControl>
        <Button
          type='submit'
          mt='1rem'
          color='white'
          backgroundColor='resolveOrange'
        >
          Save
        </Button>
      </form>
    </Flex>
  )
}
