import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Text, Flex, Button, FormLabel } from '@chakra-ui/react'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { PopoverButton } from 'components/PopoverButton'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { useUpdateProduct } from 'features/Settings/api/updateProduct'
import { useDeleteProduct } from 'features/Settings/api/deleteProduct'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetProduct } from 'features/Settings/api/getProduct'
import { Loading } from 'components/Loading'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'

const FormHeader = () => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Edit Set Product
      </Text>
    </Flex>
  )
}

const ProductState = {
  supplier_id: { label: '', value: 0 },
  id: 0,
  description: '',
  part_code: '',
  unit_price: '0.00',
  mark_up_percentage: 0,
  delivery_price: '0.00',
  quantity: 1,
  type_id: { value: 1, label: 'Goods'}, 
  minimum_term_id: 0,
  payment_plan_id: 0,
  fixed_minimum_term_id: 0,
  client_cost: '0.00'
}

export const EditProductForm = ({ product, selectRef }) => {
  const {
    register,
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: ProductState })

  const watchTypeId = watch('type_id')
  const watchDescription = watch('description')

  const updateProduct = useUpdateProduct()
  const handleProductUpdate = form => {
      updateProduct.mutate({ form, productId: product.id })
  }

  const deleteProduct = useDeleteProduct()
  const handleDeleteProduct = useCallback(async () => {
    deleteProduct.mutateAsync({ productId: product.id })
    selectRef.current.clearValue()
  }, [selectRef, deleteProduct, product])

  useEffect(() => {
    reset(product)
  }, [reset, product]);

  if(product.error) return <SomethingBroke message='Failed to get product' error={product.error.message} />
  if(product.isLoading || product.isFetching) return <Loading />
  return (
    <form onSubmit={handleSubmit(handleProductUpdate)}>
      <FormHeader />
      <SupplierSelectInput
        control={control}
        name='supplier_id'
        errors={errors}
        register={undefined}
        required
        ignoreOtherSupplier={false}
      />

      <SupplierProductTypeSelectInput
        control={control}
        name='type_id'
        errors={errors}
      />

        <ItemDescriptionInput
          register={register}
          name='description'
          required
          errors={errors}
          rules={{ maxLength: { value: 90, message: 'Description must be under 90 characters' }}}
        />
      {watchTypeId.value === 2 && (
        <>
          <FormLabel mt='1rem'>
            Subscription Options
          </FormLabel>
          <Flex>
            <MinimumTermSelectInput
              control={control}
              errors={errors}
              name='minimum_term_id'
              mr='1rem' required
            />
            <PaymentPlanSelectInput 
              control={control} errors={errors} name='payment_plan_id' required 
            />
          </Flex>
        </>
      )}

      <ItemPartCodeInput
        showLabel
        register={register}
        name='part_code'
        mt='1rem'
        errors={errors}
      />
      
      <Flex mt='1rem'>
        <ItemQuantityInput 
          control={control}
          name='quantity'
          mr='1rem'
          required
          errors={errors}
        />
        <ItemMarkupInput
          control={control}
          name='mark_up_percentage'
          mr='1rem'
          errors={errors}
          isDisabled={false}
          setValue={setValue}
          required
        />

        <ItemDeliveryPriceInput
          control={control}
          name='delivery_price'
          errors={errors}
          required={undefined}
        />
      </Flex>

      <Flex mt='1rem'>
        <ItemUnitPriceInput
          isDisabled={false}
          control={control}
          name='unit_price'
          errors={errors}
          mr='1rem'
          required
          setValue={setValue}
        />
        <ItemClientCostInput 
          control={control}
          name='client_cost'
          errors={errors}
          isDisabled={false} 
          setValue={setValue}
          required
        />
      </Flex>

      <Flex mt='1rem'>
        <Button isLoading={updateProduct.isLoading} type='submit' color='white' backgroundColor='resolveOrange'>
          Save
        </Button>
        <PopoverButton
          title='Delete Product'
          message='Are you sure you want to delete this user?'
          isLoading={deleteProduct.isLoading}
          handleClick={handleDeleteProduct}
          buttonTitle='Delete'
          color='resolveOrange'
          ml='1rem'
          backgroundColor='white'
        />
      </Flex>
    </form>
  )
}
