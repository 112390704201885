import { useResolveToast } from "hooks/useResolveToast"
import { useAuthenticatedClient } from "hooks/useAuthenticatedClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const useBulkOrder = (quoteId) => {
    const apiClient = useAuthenticatedClient()
    const { toastFail, toastSuccess } = useResolveToast()
    const history = useNavigate()
    const queryClient = useQueryClient()
    return useMutation(async ({ items, form }) => {
        const hydrated = {
            ...form,
            at_billing_code_id: form.at_billing_code_id.value,
            payment_method_id: form.payment_method_id.value,
            client_id: form.client_id.value
        }

        await apiClient({
            url: '/quotes/bulk/ordered',
            method: 'post',
            data: { items, form: hydrated }
        })
        return items.length
    }, {
        onSuccess: (itemCount) => {
            toastSuccess(`Items successfully ordered`)
            queryClient.invalidateQueries(['quote-items']) 
            history(`/quotes/${quoteId}`)
        },
        onError: () => {
            toastFail('Failed to update items')
        }
    })
}
