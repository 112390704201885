import { useController } from 'react-hook-form'

import { createFilter, Select } from 'chakra-react-select'

export const ControlledSelect = ({
  control,
  name,
  rules,
  options,
  isLoading,
  isClearable,
  searchMode = 'start',
  ...restProps
}) => {
  const {
    field: { onChange, onBlur, value, ref }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <Select
      name={name}
      isClearable={isClearable}
      ref={ref}
      filterOption={createFilter({ ignoreAccents: false, matchFrom: searchMode })}
      onChange={onChange}
      isLoading={isLoading}
      onBlur={onBlur}
      value={value}
      options={options}
      {...restProps}
    />
  )
}
