import { useForm } from 'react-hook-form'
import {
  Text,
  Flex
} from '@chakra-ui/react'
import { Form } from './Form'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useUpdateAutotaskSettings } from 'features/Settings/api/updateAutotaskSettings'
import { useGetHubspotSettings } from 'features/Settings/api/getHubspotSettings'

const autotaskSettingState = {
  defaultRoleId: ''
}

const useTestHubspotAPI = async () => (true)
export const HubspotSettingsContainer = () => {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: autotaskSettingState
  })

  const testHubspotAPI = useTestHubspotAPI()

  const onSuccess = data => {
    reset(data)
  }
  const hubspotSettings = useGetHubspotSettings({ onSuccess })
  const updateHubspotSettings = useUpdateAutotaskSettings()

  const handleUpdate = form => {
    updateHubspotSettings.mutate({ form })
  }

  if(hubspotSettings.isLoading || hubspotSettings.isFetching) return <Loading />
  if(hubspotSettings.error) return <SomethingBroke message='Failed to load HubSpot settings' error={hubspotSettings.error.message} />
  return (
    <>
      <Flex flexFlow='column'>
        <Flex alignItems='center'>
          <Text
            color='resolveOrange'
            mr='1rem'
            fontSize='2rem'
            fontWeight='600'
          >
            HubSpot
          </Text>
        </Flex>
      </Flex>
      <Form register={register} handleSubmit={handleSubmit} handleUpdate={handleUpdate} testAutotaskAPI={testHubspotAPI} />
    </>
  )
}
