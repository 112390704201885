import { useCallback, useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { UserContext } from 'features/Auth/context/user.context'
import { Form } from './Form'
import { useCreateQuote } from 'features/Quotes/api/createQuote'
import { useGetProduct } from 'features/Quotes/api/getProduct'
import { useGetSupplier } from 'features/Quotes/api/getSupplier'

const INITIAL_STATE = {
  client_id: '',
  engineer_id: 0,
  quantity: 1,
  unit_price: 0.00,
  mark_up_percentage: 45,
  delivery_price: 0,
  other_supplier: '',
  client_cost: 0.00,
  internal_description: '',
  hubspot_record_id: '',
  type_id: { value: 1, label: 'Goods'},
  description: '',
  subscription_id: '',
  minimum_term_id: null,
  payment_plan_id: null,
  part_code: '',
  set_product: null,
  supplier_id: null
}

export const AddQuote = () => {
  const { engineer } = useContext(UserContext)
  const methods = useForm({
    defaultValues: INITIAL_STATE
  })

  const resetField = methods.resetField
  const setValue = methods.setValue

  const productId = methods.watch('set_product')
  const productTypeId = methods.watch('type_id')
  const supplierId = methods.watch('supplier_id')

  useEffect(() => {
    if(supplierId && productId) {
      resetField('part_code')
      resetField('description')
      resetField('client_cost')
      resetField('delivery_price')
      resetField('unit_price')
      resetField('quantity')
      resetField('mark_up_percentage')
      resetField('internal_description')
    }
  }, [productId, resetField, supplierId])

  useEffect(() => {
    if(productTypeId) {
      resetField('delivery_price')
    }
  }, [productTypeId, resetField])

  const createQuote = useCreateQuote()
  const handleAddQuote = useCallback(async form => {
    createQuote.mutate({ quote: form, engineerId: engineer.quotes.id })
  }, [createQuote, engineer])

  const recalcClientCost = ({ markUp, unitPrice }) => {
    const newClientCost = unitPrice * ((markUp / 100) + 1)
    return newClientCost.toFixed(2)
  }

  const product = useGetProduct({ productId: productId?.value, onSuccess: product => {
    setValue('part_code', product.part_code)
    setValue('client_cost', product.client_cost)
    setValue('unit_price', product.unit_price)
    setValue('description', product.description)
    setValue('mark_up_percentage', product.mark_up_percentage)
    setValue('delivery_price', product.delivery_price)
    if(productTypeId?.value === 2) {
      setValue('minimum_term_id', product.minimum_term_id)
      setValue('payment_plan_id', product.payment_plan_id)
    }
  }})

  const supplier = useGetSupplier({ supplierId, productTypeId, onSuccess: supplier => {
    setValue('delivery_price', supplier.default_delivery)
  }})

  return (
    <FormProvider {...methods}>
      <Form
        formSubmit={handleAddQuote}
        productIsLoading={product.isLoading}
        setValue={setValue}
      />
    </FormProvider>
  )
}
