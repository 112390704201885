import { Loading } from 'components/Loading';
import {
    Button, ButtonGroup
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUpdateItemOrderDetails } from '../api/updateItemOrderDetails';
import { UserContext } from 'features/Auth/context/user.context';
import { useGetItemOrderDetails } from '../api/getItemOrderDetails';
import { getNavigationLinks } from '../utils';
import { OrderContext } from '../index';
import { TicketDescriptionInput } from '../components/TicketDescriptionInput';
import { DeliveryAddressInput } from '../components/DeliveryAddressInput';
import { FurtherActionModal } from '../components/FurtherActionModal';
import { ServicesDetailInput } from '../components/ServicesDetailInput';
import { ApproverInput } from '../components/ApproverInput';

export const OrderDetailsForm = () => {
    const { itemId, quoteId } = useParams();
    const { setStepSave } = useContext(OrderContext);

    const { engineer } = useContext(UserContext);
    const location = useLocation()
    const { nextStep } = useMemo(() => {
        return getNavigationLinks(location.pathname)
    }, [location.pathname])

    const orderForm = useFormContext()

    const orderDetails = useGetItemOrderDetails({
        itemId,
        onSuccess: data => {
            orderForm.setValue(
                'at_client_contact_id',
                data.at_client_contact_id
            );
            orderForm.setValue('ticket_description', data.ticket_description);
            orderForm.setValue('delivery_address', data.delivery_address);
            orderForm.setValue('device_build_tier', data.device_build_tier);
            orderForm.setValue('device_setup_location', data.device_setup_location);
            orderForm.setValue('approver', data.approver);
            orderForm.setValue('estimated_hours', data.estimated_hours ?? 0);
            orderForm.setValue('follow_up', data.follow_up);
            orderForm.setValue('item_id', data.item_id);
            orderForm.setValue('new_user', data.new_user);
            orderForm.setValue('new_user_details', data.new_user_details);
            orderForm.setValue('notes', data.notes);
            orderForm.setValue('services', data.services);
        },
    });

    const updateItemOrderDetails = useUpdateItemOrderDetails();
    const navigate = useNavigate()

    const handleSave = useCallback(async () => {
        if (!(await orderForm.trigger())) {
            return;
        }

        const formData = orderForm.getValues();
        await updateItemOrderDetails.mutateAsync({
            itemId,
            form: formData,
            engineerId: engineer.quotes.id,
        });

        orderForm.reset({}, { keepValues: true });
        navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
    }, [engineer, orderForm, updateItemOrderDetails, itemId, nextStep, navigate, quoteId])

    useEffect(() => {
        setStepSave(() => handleSave);
        return () => setStepSave(null);
    }, [setStepSave, handleSave]);

    if (orderDetails.isLoading) return <Loading />;

    return (
            <form onSubmit={orderForm.handleSubmit(handleSave)}>
                <TicketDescriptionInput
                    name="ticket_description"
                    required
                    showLabel
                />

                <ServicesDetailInput name="services" />

                <DeliveryAddressInput
                    name="delivery_address"
                    rules={{}}
                    required={false}
                    showLabel
                    mt="1rem"
                    mb="1rem"
                />

                <ApproverInput
                    name="approver" 
                    rules={{ maxLength: { value: 250, message: "Field too long. Max 250 chars" }}}
                    showLabel
                    required
                    mb="1rem"
                />

                <FurtherActionModal />

                <ButtonGroup mt="1rem">
                    <Button
                        color="white"
                        colorScheme="resolveOranger"
                        type="submit"
                    >
                        Save
                    </Button>
                </ButtonGroup>
            </form>
    );
};
