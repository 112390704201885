import { useFormContext, useWatch } from 'react-hook-form';
import {
    useDisclosure,
    Button,
    Flex,
    Icon,
    Modal,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ButtonGroup,
} from '@chakra-ui/react';

import { EstimatedHoursInput } from './EstimatedHoursInput';
import { NewUserInput } from './NewUserInput';
import { BuildTierSelectInput } from 'components/Form/BuildTierSelectInput';
import { NotesInput } from './NotesInput';
import {
    FaBuilding,
    FaClock,
    FaHome,
    FaLaptop,
    FaUserPlus,
} from 'react-icons/fa';
import { DeviceBuildTypeSelect } from 'components/Form/DeviceBuildTypeSelect';
import { useEffect } from 'react';

const OrderStatusIconGroup = () => {
    const newUser = useWatch({ name: 'new_user' });
    const estimatedHours = useWatch({ name: 'estimated_hours' });
    const buildTier = useWatch({ name: 'device_build_tier' });
    const setupLocation = useWatch({ name: 'device_setup_location' });

    return (
        <ButtonGroup spacing={4}>
            {estimatedHours > 0 && (
                <Flex direction="row" alignItems="center" color="resolveGreen">
                    <Icon as={FaClock} h={25} w={25} mr="0.4rem" />
                    <Text
                        justifySelf="center"
                        textAlign="center"
                        alignSelf="center"
                    >
                        {' '}
                        {estimatedHours}hrs
                    </Text>
                </Flex>
            )}
            {newUser && (
                <Flex direction="row" alignItems="center" color="resolveGreen">
                    <Icon as={FaUserPlus} h={25} w={25} mr="0.4rem" />
                    <Text
                        justifySelf="center"
                        textAlign="center"
                        alignSelf="center"
                    >
                        New User
                    </Text>
                </Flex>
            )}
            {buildTier && (
                <Flex direction="row" alignItems="center" color="resolvePink">
                    <Icon as={FaLaptop} h={25} w={25} mr="0.4rem" />
                    <Text
                        justifySelf="center"
                        textAlign="center"
                        alignSelf="center"
                    >
                        {buildTier.label}
                    </Text>
                </Flex>
            )}
            {setupLocation && (
                <Flex
                    direction="row"
                    alignItems="center"
                    color={
                        setupLocation?.value === 0
                            ? 'resolveYellow'
                            : 'resolvePurple'
                    }
                >
                    <Icon
                        as={
                            setupLocation?.value === 0
                                ? FaHome
                                : FaBuilding
                        }
                        h={25}
                        w={25}
                        mr="0.4rem"
                    />
                    <Text
                        justifySelf="center"
                        textAlign="center"
                        alignSelf="center"
                    >
                        {setupLocation.label}
                    </Text>
                </Flex>
            )}
        </ButtonGroup>
    );
};

export const FurtherActionModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const orderForm = useFormContext();
    const followUp = useWatch({ name: 'follow_up' });

    const handleOpenModal = () => {
        orderForm.setValue('follow_up', true);
        onOpen();
    };

    const handleCloseModal = async () => {
        if (
            !(await orderForm.trigger(['estimated_hours', 'new_user_details']))
        ) {
            return;
        }
        if (orderForm.getValues('estimated_hours') < 1) {
            orderForm.setValue('follow_up', false);
        }
        onClose();
    };

    useEffect(() => {
        orderForm.register('device_setup_location', { label: '', value: null });
    }, [orderForm]);

    return (
        <Flex>
            <ButtonGroup>
                <Button
                    variant="outline"
                    colorScheme={followUp ? 'resolveGreener' : 'resolveOranger'}
                    onClick={handleOpenModal}
                >
                    Work Required
                </Button>
                <OrderStatusIconGroup />
            </ButtonGroup>

            <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color="resolveOrange">
                        Further Work
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <EstimatedHoursInput name="estimated_hours" />
                        <NewUserInput name="new_user" />
                        <BuildTierSelectInput
                            mt="1rem"
                            control={orderForm.control}
                            errors={orderForm.formState.errors}
                            name="device_build_tier"
                        />
                        {orderForm.getValues('device_build_tier') && (
                            <DeviceBuildTypeSelect
                                mt="1rem"
                                name="device_setup_location"
                            />
                        )}
                        <NotesInput name="notes" />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="resolveOranger"
                            color="white"
                            onClick={handleCloseModal}
                        >
                            Okay
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};
