import * as XLSX from 'xlsx/xlsx.mjs'
import Papa from 'papaparse'
import JsPDF from 'jspdf'
import _ from 'lodash'
import 'jspdf-autotable'

export const getExportFileBlob = ({ columns, data, fileType, fileName }) => {
  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map(col => col.exportValue)
    const csvString = Papa.unparse({ fields: headerNames, data })
    const csvStringBom = '\uFEFF' + csvString;
    return new Blob([csvStringBom], { type: 'text/csv' })
  } else if (fileType === 'xlsx') {
    // XLSX example

    const header = columns.map(c => c.exportValue)
    const compatibleData = data.map(row => {
      const obj = {}
      header.forEach((col, index) => {
        obj[col] = row[index]
      })
      return obj
    })

    const wb = XLSX.utils.book_new()
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header
    })
    XLSX.utils.book_append_sheet(wb, ws1, 'Report Data')
    XLSX.writeFile(wb, `${fileName}.xlsx`)

    // Returning false as downloading of file is already taken care of
    return false
  }
  // PDF example
  if (fileType === 'pdf') {
    const headerNames = columns.map(column => column.exportValue)
    const doc = new JsPDF()
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: 'left',
        valign: 'center',
        fontSize: 11
      }
    })
    doc.save(`${fileName}.pdf`)

    return false
  }

  // Other formats goes here
  return false
}

export const preformatFloat = (float) => {
  if (!float) {
    return ''
  }

  // Index of first comma
  const posC = float.indexOf(',')

  if (posC === -1) {
    // No commas found, treat as float
    return float
  }

  // Index of first full stop
  const posFS = float.indexOf('.')

  if (posFS === -1) {
    // Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
    return float.replace(/,/g, '.')
  }

  // Uses both commas and full stops - ensure correct order and remove 1000s separators
  return posC < posFS
    ? float.replace(/,/g, '')
    : float.replace(/\./g, '').replace(',', '.')
}

export const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const calculateTotalForProperty = (tableData, property = 'cost') => {
  return _.sum(_.map(tableData, d => parseFloat(preformatFloat(d[property]))))
}