import { useUser } from './useUser'
import { axiosClient } from 'lib/axiosClient'

export const useAuthenticatedClient = () => {
  const { engineer } = useUser()

  return async (options) => {
    const { data, ...rest } = options
    return axiosClient({
      ...rest,
      data: {
        ...data,
        engineerId: engineer.id
      }
    })
  }
}
