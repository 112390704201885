import { FormControl, FormLabel } from '@chakra-ui/react';
import { ControlledSelect } from './ControlledSelect';

export const DeviceBuildTypeSelect = ({
    control,
    name,
    required,
    selectedOptionColor = 'resolveOranger',
    placeholder = 'Build Location',
    ...props
}) => {

    const setupLocations = [{ label: 'Remote', value: 1 }, { label: 'Resolve Office', value: 0 }]

    return (
        <FormControl {...props}>
            <FormLabel>Device Setup Location</FormLabel>
            <ControlledSelect
                name={name}
                control={control}
                options={setupLocations}
                rules={{ required }}
                placeholder={placeholder}
                selectedOptionColor={selectedOptionColor}
            />
        </FormControl>
    );
};
