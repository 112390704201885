import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation, useQueryClient } from "react-query"

export const updateItem = async ({ itemId, item, itemLocation, engineerId }) => {
  const normalizedItem = {
    ...item,
    won: item.won ? 1 : 0,
    checked_out: item.checked_out ? 1 : 0,
    invoiced: item.invoiced ? 1 : 0,
    type_id: item.type_id.value,
    item_location: item.item_status_id === 4 ? itemLocation : '',
    payment_method_id: item.payment_method_id?.value || null,
    checked_out_by: item.checked_out_by?.value || null,
    approved_posted: item.approved_posted ? 1 : 0,
    supplier_id: item.supplier_id.value,
    date_ordered: item.date_ordered ? moment(item.date_ordered).format('YYYY-MM-DD HH:mm:ss') : null,
    date_added: item.date_added ? moment(item.date_added).format('YYYY-MM-DD HH:mm:ss') : null,
    invoice_date: item.invoice_date ? moment(item.invoice_date).format('YYYY-MM-DD HH:mm:ss') : null,
    minimum_term_id: item.minimum_term_id?.value || 0,
    payment_plan_id: item.payment_plan_id?.value || 0,
    at_billing_code_id: item.at_billing_code_id?.value || null,
    hubspot_record_id: item.hubspot_record_id?.value || null
  }

  await axiosClient({
    url: `/quotes/item/${itemId}`,
    method: 'patch',
    data: normalizedItem
  })
}

export const useUpdateQuoteItem = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()

  return useMutation(updateItem, {
    onSuccess: () => {
      toastSuccess('Item updated succesfully')
      queryClient.invalidateQueries(['quote-items'])
      queryClient.invalidateQueries(['quote-item'])
    },
    onError: () => {
      toastFail('Failed to update item')
    }
  })
}