import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom'
import { Link, Text, Spacer, Flex } from '@chakra-ui/react'

import { Form } from './Form'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useCreateItem } from 'features/Quotes/api/createItem'
import { useGetProduct } from 'features/Quotes/api/getProduct'
import { useGetSupplier } from 'features/Internal/api/getSupplier'
import { useEffect } from 'react'
import { useGetQuote } from 'features/Quotes/api/getQuote'

const INITIAL_ITEM_STATE = {
  client_cost: 0.00,
  delivery_price: 0,
  description: '',
  mark_up_percentage: 45,
  part_code: '',
  quantity: 1,
  hubspot_record_id: '',
  supplier_id: '',
  type_id: { value: 1, label: 'Goods' },
  unit_price: 0.00,
  item_status_id: 8,
  minimum_term_id: 0,
  internal_description: '',
  payment_plan_id: 0,
  set_product: undefined
}

export const AddItem = () => {
  const { quoteId } = useParams()
  const history = useNavigate()
  const formMethods = useForm({
    defaultValues: INITIAL_ITEM_STATE
  })

  const resetField = formMethods.resetField
  const setValue = formMethods.setValue

  const createItem = useCreateItem(quoteId)

  const quote = useGetQuote({ quoteId, onSuccess: async (quote) => {
    setValue("client_id", quote.client_id)
  }})

  const handleFormSubmit = form => {
    createItem.mutate({ item: form, quoteId })
  }
  const productId = useWatch({ control: formMethods.control, name: 'set_product' })
  const productTypeId = useWatch({ control: formMethods.control, name: 'type_id' })
  const supplierId = useWatch({ control: formMethods.control, name: 'supplier_id' })

  useEffect(() => {
    if(supplierId && productId) {
      resetField('part_code')
      resetField('description')
      resetField('client_cost')
      resetField('delivery_price')
      resetField('unit_price')
      resetField('quantity')
      resetField('mark_up_percentage')
      resetField('internal_description')
    }
  }, [supplierId, productId, resetField])

  useEffect(() => {
    if(productTypeId) {
      resetField('delivery_price')
    }
  }, [productTypeId, resetField])

  const recalcClientCost = ({ markUp, unitPrice }) => {
    const newClientCost = unitPrice * ((markUp / 100) + 1)
    return newClientCost.toFixed(2)
  }

  const product = useGetProduct({ productId: productId?.value, onSuccess: product => {
    setValue('part_code', product.part_code)
    setValue('unit_price', product.unit_price)
    setValue('description', product.description)
    setValue('mark_up_percentage', product.mark_up_percentage)
    setValue('client_cost', product.client_cost)
    setValue('delivery_price', product.delivery_price)
    if(productTypeId?.value === 2) {
      setValue('minimum_term_id', product.minimum_term_id)
      setValue('payment_plan_id', product.payment_plan_id)
    }
  }})

  const supplier = useGetSupplier({ supplierId, productTypeId, onSuccess: supplier => {
    setValue('delivery_price', supplier.default_delivery)
  }})

  return (
    <FormProvider {...formMethods}>
      <Flex mb='1rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Add Item
        </Text>
        <Spacer />
        <Link
          as={ReactLink}
          alignSelf='center'
          color='resolveOrange'
          to='#'
          _hover={{ color: 'gray.200' }}
          onClick={() => history(`/quotes/${quoteId}`)}
        >
          Back
        </Link>
      </Flex>
      <Form
        addItem={handleFormSubmit}
        setValue={setValue}
      />
    </FormProvider>
  )
}
