import { FormControl, FormLabel, Flex, Spinner, Text, Icon, IconButton } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { ControlledSelect } from './ControlledSelect';
import { useQuery } from 'react-query';
import { axiosClient } from 'lib/axiosClient';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

const getDeals = async context => {
    const { signal, queryKey } = context;
    const [, clientId, quoteId] = queryKey;

    const { data } = await axiosClient({
        url: `/hubspot/deals/${clientId}`,
        method: 'put',
        signal,
        data: { quoteId },
    });
    return data;
};

const useGetHubspotDeals = (clientId, quoteId) => {
    return useQuery(['form-hubspot-deals', clientId, quoteId], {
        placeholderData: [],
        enabled: !!clientId,
        queryFn: getDeals,
    });
};

export const HubspotDealSelect = ({
    name = 'hubspot_record_id',
    control,
    required,
    errors,
    clientId,
    selectedOptionColor = 'resolveOranger',
}) => {
    const form = useFormContext();
    const { quoteId } = useParams();
    const deals = useGetHubspotDeals(clientId, quoteId);

    if (!clientId) {
        return (
            <FormControl
                isRequired={required}
                isInvalid={!!form.formState.errors[name]}
                mb="1rem"
            >
                <FormLabel>HubSpot Deal</FormLabel>
                <Text mt="1rem">Select a Company to load HubSpot Deals</Text>
            </FormControl>
        );
    }

    return (
        <FormControl
            isRequired={required}
            isInvalid={!!form.formState.errors[name]}
            mb="1rem"
        >
            <FormLabel>HubSpot Deal</FormLabel>
            {deals.isLoading || deals.isFetching ? (
                <Flex mt="1rem">
                    <Spinner
                        thickness="5px"
                        emptyColor="gray.200"
                        color="resolveOrange"
                    />
                    <Text ml="1rem">Loading</Text>
                </Flex>
            ) : !deals.data?.company ? (
                <Flex alignItems="center">
                    <Text>Company not found in HubSpot</Text>
                    <IconButton
                        ml="1rem"
                        variant="ghost"
                        fontSize="15px"
                        onClick={() => deals.refetch()}
                        icon={<Icon color="resolveOrange" as={RepeatIcon} />}
                        colorScheme="resolveOranger"
                    />
                </Flex>
            ) : deals.data.deals.length === 0 ? (
                <Text mt="1rem">
                    No deals found in HubSpot. Please create one before creating
                    the Quote
                </Text>
            ) : (
                <ControlledSelect
                    name={name}
                    searchMode="any"
                    control={form.control}
                    isLoading={deals.isLoading || deals.isFetching}
                    rules={{ required }}
                    options={deals.data.deals}
                    selectedOptionColor={selectedOptionColor}
                />
            )}
        </FormControl>
    );
};
