import { blobToBase64 } from '.'
import { loginRequest } from '../authConfig'
import { msalInstance } from 'index'

export async function getUserPhoto (upn) {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account
    })
    .catch(error => {
      msalInstance.acquireTokenRedirect({ ...loginRequest })
    })
  const headers = new Headers()
  const bearer = `Bearer ${response.accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'image/jpeg')

  const options = {
    method: 'GET',
    headers,
    responseType: 'blob'
  }

  return fetch(`https://graph.microsoft.com/v1.0/users/${upn}/photo/$value`, options)
    .then(response => response.blob())
    .then(async blob => await blobToBase64(blob))
    .catch(error => console.log(error))
}
