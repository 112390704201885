import { useForm } from 'react-hook-form'
import { Button, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Header } from '../Header'
import { PopoverButton } from 'components/PopoverButton'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useUpdateBuildTier } from 'features/Settings/api/updateBuildTier'
import { useDeleteBuildTier } from 'features/Settings/api/deleteBuildTier'
import { useGetBuildTier } from 'features/Settings/api/getBuildTier'

const TeamState = {
  name: ''
}

const TeamNameInput = ({
  register,
  name,
  buildTier,
}) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel>Name</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}



export const EditBuildTier = ({
  currentName,
  buildTierId,
  selectRef,
  setBuildTier,
  ...restProps
}) => {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: TeamState
  })

  const updateBuildTier = useUpdateBuildTier()

  const handleBuildTierUpdate = async (form) => {
    await updateBuildTier.mutateAsync({ form, buildTierId })
    selectRef.current.clearValue()
  } 

  const deleteBuildTier = useDeleteBuildTier()
  const handleDeleteBuildTier = async () => {
    await deleteBuildTier.mutateAsync({ buildTierId })
    selectRef.current.clearValue()
  }

  const onSuccess = buildTier => {
    reset(buildTier)
  }
  const buildTier = useGetBuildTier({ buildTierId, onSuccess })
  
  if(buildTier.isLoading) return <Loading />
  if(buildTier.error) return <SomethingBroke message='Failed to get Team' error={buildTier.error.message} />
  return (
    <form onSubmit={handleSubmit(handleBuildTierUpdate)} {...restProps}>
      <Header name={buildTier.data.name} />
      <TeamNameInput register={register} buildTier={buildTier} name='label' />
      <Button type='submit' color='white' backgroundColor='resolveOrange'>
        Save
      </Button>
      <PopoverButton
        title='Delete Build Tier'
        message='Are you sure you want to delete this build tier?'
        handleClick={handleDeleteBuildTier}
        buttonTitle='Delete'
        color='resolveOrange'
        ml='1rem'
        backgroundColor='white'
        isLoading={deleteBuildTier.isLoading}
      />
    </form>
  )
}
