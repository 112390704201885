import { useResolveToast } from 'hooks/useResolveToast';
import { axiosClient } from 'lib/axiosClient';
import { useMutation, useQueryClient } from 'react-query';

export const updateItemOrderDetails = async ({ itemId, form, engineerId }) => {
    const {
        ticket_description,
        new_user,
        new_user_details,
        delivery_address,
        follow_up,
        device_build_tier,
        device_setup_location,
        at_client_contact_id,
        services,
        estimated_hours,
        notes,
        approver
    } = form;

    const nForm = {
        item_id: itemId,
        approver,
        ticket_description: ticket_description,
        new_user: !!new_user,
        follow_up: !!follow_up,
        delivery_address,
        ordered_by: engineerId,
        new_user_details,
        device_build_tier: device_build_tier ? device_build_tier.value : null,
        at_client_contact_id: at_client_contact_id?.value || null,
        device_setup_location: device_setup_location ? device_setup_location?.value : null,
        services: services,
        estimated_hours: estimated_hours ? estimated_hours : 0,
        notes
    };


    await axiosClient({
        url: `/quotes/item/order/${itemId}`,
        method: 'patch',
        data: nForm,
    });
};

export const useUpdateItemOrderDetails = () => {
    const queryClient = useQueryClient();
    const { toastSuccess, toastFail } = useResolveToast();
    return useMutation(updateItemOrderDetails, {
        onSuccess: () => {
            toastSuccess('Item order details saved successfully');
            queryClient.invalidateQueries(['quote-items']);
        },
        onError: () => {
            toastFail('Failed to update item order details');
        },
    });
};
